<template>
  <div class="ResourceCenter document-content">溯源中心</div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.ResourceCenter {
}
</style>